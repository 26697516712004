@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@300;400;500&display=swap");

:root {
  filter: invert(100%)
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "IBM Plex Mono", sans-serif;
  background-color: #f7f7f7;
}

h1 {
  align-items: center;
  margin-bottom: 12px;
}

h3 {
  align-items: center;
  text-align: center;
  margin-bottom: 10px;
}

.col-flex-container {
  max-width: 80rem;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .1rem;
}

.row-flex-container {
  max-width: 80rem;
  margin: 0 auto;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .5rem;
}

.row-flex-container2 {
  max-width: 1000rem;
  margin: 0 auto;

  display: inline-flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: .5rem;
}

.stretch-label {
  max-width: 100rem;
  margin: 0 auto;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .5rem;
}

.input-box {
  max-width: 10rem;
}

.card {
  margin: 30px;
  border: 4px solid #222;
}

.card::after {
  content: "";
  display: flex;
}

.avatar {
  width: 100%;
  display: block;
}

.data {
  padding: 32px;
  padding-top: 24px;
}

.option-list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 16px;
}

.option {
  padding: 2px 12px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
}

/* CSS */
.button{
  appearance: button;
  background-color: #e8844a;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, .15), rgba(255, 255, 255, 0));
  border: 1px solid #e8844a;
  border-radius: 1rem;
  box-shadow: rgba(255, 255, 255, 0.15) 0 1px 0 inset,rgba(46, 54, 80, 0.075) 0 1px 1px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: Inter,sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  margin: 5px;
  padding: .5rem 1rem;
  text-align: center;
  text-transform: none;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  cursor: pointer;
}

.button-20:focus:not(:focus-visible),
.button-20:focus {
  outline: 0;
}

.button-20:hover {
  background-color: #3733E5;
  border-color: #3733E5;
}

.button-20:focus {
  background-color: #413FC5;
  border-color: #3E3BBA;
  box-shadow: rgba(255, 255, 255, 0.15) 0 1px 0 inset, rgba(46, 54, 80, 0.075) 0 1px 1px, rgba(104, 101, 235, 0.5) 0 0 0 .2rem;
}

.button-20:active {
  background-color: #3E3BBA;
  background-image: none;
  border-color: #3A38AE;
  box-shadow: rgba(46, 54, 80, 0.125) 0 3px 5px inset;
}

.button-20:active:focus {
  box-shadow: rgba(46, 54, 80, 0.125) 0 3px 5px inset, rgba(104, 101, 235, 0.5) 0 0 0 .2rem;
}

.button-20:disabled {
  background-image: none;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.white-inverse-text {
  color: #000000
}

.red-inverse-text {
  color: #009fff
}